<template>
  <div class="category">
    <hcc-table
      :title="$t('configuration.categories.title')"
      :initialPage="1"
      :columns="columns"
      :rows="categoriesList"
      :group-options="{ enabled: true, collapsable: true }"
      @edit="edit"
      @delete="deleteOption"
      @add="openAddCategory"
      @addSubgroup="addSubCategory"
      @editSubgroup="editSubCategory"
      :pagination="false"
      class="left-align"
    >
      <template slot="table-row" slot-scope="props">
        <hcc-input
          v-focus
          v-if="props.column.field == 'name' && props.row.editable"
          v-model.trim="props.row.name"
          @keyup.enter="editSubCategory(props.row)"
        />
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <transition name="fade">
      <hcc-modal
        :clickToClose="false"
        name="category-modal"
        :title="$t('configuration.categories.header')"
        @confirm="updateCategories"
        @cancel="cancel"
      >
        <div class="form-modal">
          <hcc-input
            class="modal-input"
            v-focuseModal
            :label="$t('common.name')"
            v-model.trim="newCategory.name"
            @keyup.enter="submit('category')"
          />
        </div>
      </hcc-modal>
    </transition>
    <transition name="fade">
      <hcc-modal
        :clickToClose="false"
        name="subcategory-modal"
        :title="$t('configuration.categories.sub-category')"
        @confirm="updateSubCategories"
        @cancel="cancel"
      >
        <div class="form-modal">
          <hcc-input
            class="modal-input"
            v-focuseModal
            :label="$t('common.name')"
            v-model.trim="options.subCat"
            @keyup.enter="submit('subcategory')"
          />
        </div>
      </hcc-modal>
    </transition>
    <transition name="fade">
      <error-modal :message="errorMessage" @error="handleErrorConfirm" />
    </transition>
    <transition name="fade">
      <hcc-confirmation />
    </transition>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import {
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_ERROR,
} from '@/eventTypes';
import ErrorHandler from '@/utils/error.handler';

export default {
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    ErrorModal: () => import('@/components/ErrorModal.vue'),
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].children[0].focus();
      },
    },
  },
  data() {
    return {
      errorMessage: '',
      delete: {},
      categories: [],
      options: {},
      newCategory: { name: '' },
      categoriesList: [],
    };
  },
  watch: {
    campaign(newVal) {
      if (newVal.categories) {
        this.categories = newVal.categories;
        this.updateCategoriesList();
      } else {
        this.categories = [];
      }
    },
  },
  computed: {
    columns() {
      return [{
        label: this.$t('common.name'),
        field: 'name',
        width: '85%',
      }];
    },
    defaultCategories() {
      return [
        'Venta',
        'No Venta',
      ];
    },
  },
  created() {
    EventBus.$on(UPDATE_CAMPAIGN_ERROR, (err) => {
      ErrorHandler.logErrors(err);
    });
  },
  mounted() {
    this.categories = this.campaign.categories;
    this.updateCategoriesList();
    this.$emit('changed', false);
  },
  destroyed() {
    EventBus.$off(UPDATE_CAMPAIGN_ERROR);
  },
  methods: {
    updateCategoriesList() {
      this.cancel();
      const list = [];
      if (this.categories && this.categories.length > 0) {
        this.categories.forEach((c, i) => {
          const children = this.childrenCategories(c);
          list.push({
            ...c,
            index: i,
            name: c.title,
            children,
          });
        });
      }
      this.categoriesList = list;
    },
    handleErrorConfirm() {
      this.cancel();
    },
    childrenCategories(cat) {
      return cat.subcategories.map(c => ({
        title: c.title,
        name: c.title,
        editable: false,
        id: c.id,
        parent: cat.id,
      }));
    },
    edit(props) {
      this.newCategory = props;
      this.$modal.show('category-modal');
    },
    updateCategories() {
      const { name, id } = this.newCategory;
      if (!this.validCategory(name)) {
        this.saveCategories({ id, title: name });
      } else {
        this.error(this.$t('configuration.error-exists'));
      }
    },
    updateSubCategories() {
      const { subCat, id } = this.options;
      if (!this.validSubCategory(subCat, id)) {
        this.saveCategories({ title: subCat, parent: id });
      } else {
        this.error(this.$t('configuration.error-exists'));
      }
    },
    cancel() {
      this.options = {};
      this.newCategory = { name: '' };
      this.delete = {};
      this.errorMessage = '';
    },
    deleteOption(props) {
      if (this.categoryMsg(props.id)) {
        this.error(this.$t('configuration.categories.delete-error'));
      } else if (this.defaultCategories.includes(props.name)) {
        this.error(this.$t('configuration.categories.default-categories-error'));
      } else {
        this.$modal.show('confirmation', {
          title: `Delete "${props.name}"`,
          description: this.$t('configuration.confirmation'),
          variant: 'error',
          confirm: () => this.saveCategories({
            id: props.id,
            parent: props.parent,
            remove: true,
          }),
        });
      }
    },
    categoryMsg(id) {
      return this.campaign.messages.find(c => c.value === id);
    },
    openAddCategory() {
      this.$modal.show('category-modal');
    },
    addSubCategory(category) {
      this.options = { ...category, subCat: '' };
      this.$modal.show('subcategory-modal');
    },
    getCategory(id) {
      return this.categoriesList.find(c => c.id === id);
    },
    saveCategories(category) {
      const data = {
        id: this.campaign.id,
        categories: category,
      };
      EventBus.$emit(UPDATE_CAMPAIGN, { data, tab: 'Category' });
    },
    validSubCategory(value, id) {
      const category = this.getCategory(id);
      return category.children
        .some(c => c.name === value);
    },
    validCategory(name) {
      return (name === '') || this.categoriesList
        .some(c => c.name === name);
    },
    editSubCategory(params) {
      const data = params;
      if (data.name !== data.title && !this.validSubCategory(data.name, data.parent)) {
        this.saveCategories({
          title: data.name,
          parent: data.parent,
          id: data.id,
        });
      } else if (data.name !== data.title) {
        this.error(this.$t('configuration.error-exists'));
      }
      const parent = this.getCategory(data.parent);
      const index = parent.children.findIndex(sub => sub.id === data.id);
      data.editable = false;
      this.$set(parent, index, data);
    },
    error(msg) {
      this.errorMessage = msg;
      this.$modal.show('error-modal');
    },
    submit(id) {
      if (id === 'category' && this.newCategory.name.length > 0) {
        this.updateCategories();
        this.$modal.hide('category-modal');
      } else if (id === 'subcategory' && this.options.subCat.length > 0) {
        this.updateSubCategories();
        this.$modal.hide('subcategory-modal');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/settings/shared/_modal.scss";

::v-deep .left-align {
  table.vgt-table {
    .vgt-left-align {
      text-align: left;
    }
  }
}
</style>
